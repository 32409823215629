.card {
    margin-top: 3%;
    border: 0px solid white;
    width: 100%;
    margin-bottom: 3%;
    border-radius: 20px;
}

.justify {
    text-align: justify;
}

.telcommand {
    border-radius: 6px;
    border: 1px solid #d83526;
    display: inline-block;
    color: #fff;
    font-family: arial;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
    text-shadow: 1px 1px 0 #b23e35;
    text-align: center;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 270px;
    -moz-box-shadow: inset 0 1px 0 0 #f29c93;
    -webkit-box-shadow: inset 0 1px 0 0 #f29c93;
    box-shadow: inset 0 1px 0 0 #f29c93;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0.05, #fe1a00),
        color-stop(1, #ce0100)
    );
    background: -moz-linear-gradient(center top, #fe1a00 5%, #ce0100 100%);
    background-color: #fe1a00;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
}

.telcommand:hover {
    cursor: pointer;
    text-decoration: underline;
    background: #6d0019;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzZkMDAxOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjU2JSIgc3RvcC1jb2xvcj0iIzhmMDIyMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNhOTAzMjkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(
        top,
        rgba(109, 0, 25, 1) 0%,
        rgba(143, 2, 34, 1) 56%,
        rgba(169, 3, 41, 1) 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(109, 0, 25, 1)),
        color-stop(56%, rgba(143, 2, 34, 1)),
        color-stop(100%, rgba(169, 3, 41, 1))
    );
    background: -webkit-linear-gradient(
        top,
        rgba(109, 0, 25, 1) 0%,
        rgba(143, 2, 34, 1) 56%,
        rgba(169, 3, 41, 1) 100%
    );
    background: -o-linear-gradient(
        top,
        rgba(109, 0, 25, 1) 0%,
        rgba(143, 2, 34, 1) 56%,
        rgba(169, 3, 41, 1) 100%
    );
    background: -ms-linear-gradient(
        top,
        rgba(109, 0, 25, 1) 0%,
        rgba(143, 2, 34, 1) 56%,
        rgba(169, 3, 41, 1) 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(109, 0, 25, 1) 0%,
        rgba(143, 2, 34, 1) 56%,
        rgba(169, 3, 41, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6d0019', endColorstr='#a90329', GradientType=0);
    border-color: darkred;
}

.customButton {
    margin: auto;
    width: fit-content;
    padding: 10px;
    text-align: center;
}
