.consulting_scheme {
    display: flex;
}

.scheme_content {
    width: 1100px;
    margin: auto;
    background: #fff;
    height: 411px;
    border-top: 2px solid #707070;
    border-bottom: 2px solid #707070;
    margin-top: 70px;
}

.horizon_ruler {
    width: 1px;
    height: 431px;
    display: inline-block;
    background-color: #707070;
    margin-right: 19.49%;
    margin-top: -10px;
    position: relative;
}

.horizon_ruler.m-r_0 {
    margin-right: 0;
}

.horizon_ruler:before {
    position: absolute;
    font-size: 16px;
    font-family: "Segoe UI Semibold";
    top: -25px;
    left: -3px;
}

.horizon_ruler:nth-child(1):before {
    content: "0";
}

.horizon_ruler:nth-child(2):before {
    content: "5";
}

.horizon_ruler:nth-child(3):before {
    content: "10";
    left: -6px;
}

.horizon_ruler:nth-child(4):before {
    content: "15";
    left: -6px;
}

.horizon_ruler:nth-child(5):before {
    content: "20";
    left: -8px;
}

.horizon_ruler:nth-child(6):before {
    content: "25";
    left: -8px;
}

.horizon_ruler:after {
    position: absolute;
    font-size: 16px;
    font-family: "Segoe UI Semibold";
    bottom: -25px;
    left: -3px;
}

.horizon_ruler:nth-child(1):after {
    content: "0";
}

.horizon_ruler:nth-child(2):after {
    content: "1";
}

.horizon_ruler:nth-child(3):after {
    content: "2";
}

.horizon_ruler:nth-child(4):after {
    content: "3";
}

.horizon_ruler:nth-child(5):after {
    content: "4";
}

.horizon_ruler:nth-child(6):after {
    content: "5";
}

.horizon_ruler .vertical_ruler {
    position: absolute;
    width: 7px;
    height: 1px;
    background-color: #1a1a1a;
    display: inline-block;
    left: -7px;
}

.horizon_ruler .vertical_ruler:nth-child(1) {
    bottom: 390px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.horizon_ruler .vertical_ruler:nth-child(2) {
    bottom: 349px;
}

.horizon_ruler .vertical_ruler:nth-child(3) {
    bottom: 308px;
}

.horizon_ruler .vertical_ruler:nth-child(4) {
    bottom: 267px;
}

.horizon_ruler .vertical_ruler:nth-child(5) {
    bottom: 226px;
}

.horizon_ruler .vertical_ruler:nth-child(6) {
    bottom: 185px;
}

.horizon_ruler .vertical_ruler:nth-child(7) {
    bottom: 144px;
}

.horizon_ruler .vertical_ruler:nth-child(8) {
    bottom: 103px;
}

.horizon_ruler .vertical_ruler:nth-child(9) {
    bottom: 62px;
}

.horizon_ruler .vertical_ruler:before {
    position: absolute;
    right: 13px;
    font-size: 16px;
    font-family: "segoe-ui-bold";
}

.horizon_ruler .vertical_ruler:nth-child(1):before {
    content: "Start";
    top: 0;
    right: 21px;
}

.horizon_ruler .vertical_ruler:nth-child(2):before {
    content: "FCC Part 15/RSS 210 Tests";
    top: 0;
    width: 194px;
}

.horizon_ruler .vertical_ruler:nth-child(3):before {
    content: "EN 300 330-1,2 Tests";
    top: 0;
    width: 156px;
}

.horizon_ruler .vertical_ruler:nth-child(4):before {
    content: "EN 50364/EN50357 Tests";
    top: 0;
    width: 188px;
}

.horizon_ruler .vertical_ruler:nth-child(5):before {
    content: "EN 301 489-1/3 Tests";
    top: 0;
    width: 159px;
}

.horizon_ruler .vertical_ruler:nth-child(6):before {
    content: "EN/IEC 60950-1 Tests";
    top: 0;
    width: 160px;
}

.horizon_ruler .vertical_ruler:nth-child(7):before {
    content: "Test Reports";
    top: 0;
    width: 93px;
}

.horizon_ruler .vertical_ruler:nth-child(8):before {
    content: "Certification";
    top: 0;
}

.horizon_ruler .vertical_ruler:nth-child(9):before {
    content: "Finish";
    top: 0;
}

.es {
    position: absolute;
    top: 10px;
    right: 24px;
    border: 1px solid #707070;
    font-family: "segoe-ui-bold";
    padding: 4px 28px;
}

.es .category_circle {
    margin-left: 21px;
}

.es .category_circle:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 7px;
    border-radius: 50%;
    vertical-align: middle;
}

.es .category_circle:nth-child(2):before {
    background-color: #9b0405;
}

.es .category_circle:nth-child(3):before {
    background-color: #c18e54;
}

.es .category_circle:nth-child(4):before {
    background-color: #1a1a1a;
}

.horizon_ruler .finish {
    display: inline-block;
    width: 15px;
    height: 15px;
    position: absolute;
    background: #1a1a1a;
    top: 385px;
    right: -7px;
    border-radius: 50%;
}

.horizon_ruler .vertical_ruler:after {
    content: "";
    position: absolute;
    top: 4px;
    z-index: 1;
}

.horizon_ruler .vertical_ruler:nth-child(1):after {
}

.horizon_ruler .vertical_ruler:nth-child(2):after {
    width: 178px;
    height: 35px;
    background-color: #9b0405;
    left: 8px;
}

.horizon_ruler .vertical_ruler:nth-child(3):after {
    width: 309px;
    height: 35px;
    background-color: #c18e54;
    left: 8px;
}

.horizon_ruler .vertical_ruler:nth-child(4):after {
    width: 238px;
    height: 35px;
    background-color: #9b0405;
    left: 184px;
}

.horizon_ruler .vertical_ruler:nth-child(5):after {
    width: 352px;
    height: 35px;
    background-color: #c18e54;
    left: 260px;
}

.horizon_ruler .vertical_ruler:nth-child(6):after {
    width: 226px;
    height: 35px;
    background-color: #9b0405;
    left: 422px;
}

.horizon_ruler .vertical_ruler:nth-child(7):after {
    width: 320px;
    height: 35px;
    background-color: #c18e54;
    left: 565px;
}

.horizon_ruler .vertical_ruler:nth-child(8):after {
    width: 241px;
    height: 35px;
    background-color: #9b0405;
    left: 644px;
}

.scheme_content {
    position: relative;
    font-family: "segoe-ui-bold";
}

.scheme_content .top_text {
    position: absolute;
    top: -70px;
    left: -10px;
    font-size: 16px;
    font-family: "segoe-ui-bold";
}

.scheme_content .bottom_text {
    position: absolute;
    bottom: -70px;
    left: -22px;
    font-size: 16px;
    font-family: "segoe-ui-bold";
}

.horizon_ruler {
    font-family: "segoe-ui-bold";
}

@media (max-width: 1600px) {
    .scheme_content {
        width: 70%;
    }

    .horizon_ruler {
        margin-right: 19.46%;
    }
}

@media (max-width: 1500px) {
    .horizon_ruler {
        margin-right: 19.42%;
    }
}

@media (max-width: 1400px) {
    .horizon_ruler {
        margin-right: 19.38%;
    }
}

@media (max-width: 1300px) {
    .horizon_ruler {
        margin-right: 19.33%;
    }
}

@media (max-width: 1200px) {
    .horizon_ruler {
        margin-right: 19.26%;
    }

    .horizon_ruler .vertical_ruler:nth-child(2):after {
        width: 80px;
    }
    .horizon_ruler .vertical_ruler:nth-child(3):after {
        width: 160px;
    }
    .horizon_ruler .vertical_ruler:nth-child(4):after {
        left: 75px;
    }
    .horizon_ruler .vertical_ruler:nth-child(5):after {
        left: 200px;
        width: 270px;
    }
    .horizon_ruler .vertical_ruler:nth-child(6):after {
        left: 300px;
        width: 200px;
    }
    .horizon_ruler .vertical_ruler:nth-child(7):after {
        width: 265px;
        left: 410px;
    }
    .horizon_ruler .vertical_ruler:nth-child(8):after {
        left: 480px;
        width: 195px;
    }
}

@media (max-width: 1100px) {
    .horizon_ruler {
        margin-right: 19.19%;
    }
}

@media (max-width: 1000px) {
    .horizon_ruler:first-child {
        border-left: 1px solid #707070;
    }

    .horizon_ruler {
        margin-right: 0;
        width: 16%;
        background: transparent;
        border-right: 1px solid #707070;
    }
}

@media (max-width: 700px) {
}
