.top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    padding-top: 10px;
}
.top-paragraph {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.earnings-container {
    display: flex;
    align-items: center;
}
.earnings {
    font-size: 16px;
    margin-right: 20px;
}
.reset-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
}
.reset-btn:hover {
    text-decoration: underline;
}
.search-container {
    margin: 20px 0;
}
.searchInput {
    border-radius: 20px;
    border: 2px solid #cececd;
}
.videosearchBTn {
    background-color: #f5f2f0;
    color: black;
    border: 2px solid #cececd;
    border-radius: 20px;
    width: 100px; /* Adjust width as needed */
}
.video-card {
    border: none;
}
.video-card video {
    border-radius: 12px;
}

#category-slider {
    padding: 15px 0;
}
.category-btn {
    background-color: #f5f2f0;
    font-size: 15px;
    margin: 5px;
    border-radius: 5px;
}
.category-badge {
    background-color: #f5f2f0;
    color: black;
    border-radius: 10px;
}
.carousel-control-prev,
.carousel-control-next {
    background-color: #f5f2f0;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: invert(1);
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 20px;
}
.grid-item {
    position: relative;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}
.grid-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.grid-item img,
.grid-item video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.grid-item img.error + .fallback {
    display: block;
}
.grid-item .fallback {
    display: none;
    width: 100%;
    height: 100%;
    /* background: url("/path/to/fallback/image.png") center center no-repeat; */
    background-size: cover;
}
.grid-item.recall {
    border: 3px solid red;
}
.grid-item.recall:hover::after {
    content: "Potential Recall";
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: red;
    color: white;
    padding: 5px;
    border-radius: 5px;
    z-index: 1;
}
.grid-item .title {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
}
.custom-upload-btn {
    display: inline-block;
    cursor: pointer;
}

.file-input {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.file-input input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
}
