.card {
    margin-top: 3%;
    border: 0px solid white;
    width: 100%;
    margin-bottom: 3%;
    border-radius: 20px;
}

ul li.pngImage {
    text-align: justify;
    vertical-align: top;
    margin: 12px 0 0;
    list-style-type: none;
}

.justify {
    text-align: justify;
    margin-top: 1.5%;
}

h5 {
    color: #700203;
}

ul li a {
    color: black;
}
